<template>
  <v-app>
    <router-view name="AppBar" />
    <router-view name="TheNavigationRail" />
    <router-view name="TheDisclaimer" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style>
html,
body {
  overflow: hidden !important;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 24px;
  color: #666;
  margin: 0px;
  padding: 0px;
  margin-bottom: 15px;
}
h2 {
  font-size: 0.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.1666666667em !important;
  font-family: 'Roboto', sans-serif !important;
  text-transform: uppercase;
  background-color: rgb(221, 221, 221);
  padding-left: 10px;
  max-width: 600px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 15px;
}
h2::after {
  content: '';
  background-color: rgb(221, 221, 221);
  width: calc(100vw - 180px);
  height: 1px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

@media only screen and (max-width: 800px) {
  h2::after {
    content: '';
    background-color: rgb(221, 221, 221);
    width: calc(100vw - 40px);
    height: 1px;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

h3 {
  font-size: 18px;
  color: #222;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
}

h4 {
  font-size: 16px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
}
h5 {
  font-size: 14px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
}
h6 {
  font-size: 12px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
}

p {
  padding: 0px;
  margin: 0px;
  margin-bottom: 8px;
}

ul {
  margin-left: 20px;
  margin-bottom: 8px;
}

.markDown table {
  border-collapse: collapse;
  border: 2px solid #ddd;
  width: 100%;
}
.markDown table td {
  padding: 4px;
}

.markDown table td {
  border: 1px solid #eee;
}

.markDown table tr td:nth-child(1) {
  width: 160px;
  max-width: 160px;
}

@supports (-webkit-touch-callout: none) {
  body,
  #app,
  .v-application .v-application__wrap {
    min-height: -webkit-fill-available;
  }
}
</style>
