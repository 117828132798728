import 'roboto-fontface/css/roboto/roboto-fontface.css';
import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify.js';
import router from './plugins/router.js';

// shim global for Handlebars
window.global = globalThis;

createApp(App).use(vuetify).use(router).mount('#app');
