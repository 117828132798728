import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import routes from '../routes.js';

/**
 * @param {string|Array<string>} value
 * @returns {string}
 */
export function first(value) {
  return Array.isArray(value) ? value[0] : value;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  let { params, query } = to;
  if (to.name === 'Map' || to.name === 'MapEmbed' || to.name === 'Data') {
    const { currentKartenansichtId, error, updateVisibleLayers } = await import(
      '../composables/useLayers.js'
    );
    const id = params.kartenansichtId;
    if (id !== undefined) {
      const previousKartenansichtId = currentKartenansichtId.value;
      currentKartenansichtId.value = Array.isArray(id) ? id[0] : id;
      if (previousKartenansichtId !== currentKartenansichtId.value) {
        // Wait until watchers in useLayers have cleared the old kartenansicht
        await nextTick();
      }
    }
    // updateVisibleLayers resolves when the new kartenansicht is loaded
    await updateVisibleLayers(first(params.visibleLayers));
    // now we have all data updated and can do all the math
    if (error.value) {
      // The kartenansicht was not found, redirect
      return { name: 'Dashboard' };
    }
  }
  if (to.name === 'Map' || to.name === 'MapEmbed') {
    const { visibleLayersRouteParam } = await import(
      '../composables/useLayers.js'
    );
    const { xyzQuery } = await import('../composables/useMap.js');
    if (
      visibleLayersRouteParam.value &&
      (params.visibleLayers !== visibleLayersRouteParam.value ||
        !params.visibleLayers)
    ) {
      params = { ...params, visibleLayers: visibleLayersRouteParam.value };
    }
    if (query.x === undefined) {
      query = { ...query, ...xyzQuery.value };
    }
    if (params !== to.params || query !== to.query) {
      return {
        ...to,
        params,
        query,
      };
    }
  }
  if ('preview' in from.query && !('preview' in query)) {
    query = { ...query, preview: null };
    return { ...to, params, query };
  }
  return undefined;
});

export default router;
